
/* montserrat-alternates-regular - latin */
@font-face {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/montserrat-alternates-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/fonts/montserrat-alternates-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/montserrat-alternates-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/montserrat-alternates-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/montserrat-alternates-v17-latin-regular.svg#MontserratAlternates') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media print {
  html, body {
    height: 100%;
  }
}
